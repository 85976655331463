import React from 'react'
import { Link } from 'gatsby'
import Img from "gatsby-image"
import styled from 'styled-components'
import moment from 'moment'
import 'moment/locale/nl'
moment.locale('nl')


const BlogPostsWrapper = styled.div`
  margin-top: 180px;
`

const BlogPosts = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -2%;
`
const BlogPostSmall = styled.div`
  color: #666;
  width: 29%;
  @media (max-width: 768px) {
    width: 46%;
  }
  @media (max-width: 450px) {
    width: 96%;
  }
  margin: 2%;
`
const CardText = styled.div`
  height: 120px;
  width: 100%;
  font-weight: bold;
  background: rgba(248,248,248);
`

const BlogPostLink = styled(props => <Link {...props} />)`
  color: #666;
  text-decoration: none;
`

const Title = styled.h5`
  padding: 5px 20px;
`

const Date = styled.div`
  padding: 20px 20px 0px;
  color: #279896;
`



const RelatedBlogPosts = ({ data }) => {
  return (
    <BlogPostsWrapper>
      <h4>Andere activiteiten dit seizoen:</h4>
      <BlogPosts>
        {
          data.blogposts.edges.filter(({ node }) => node.childMarkdownRemark.id !== data.blogpost.id).map(({ node }) => {
            const { childMarkdownRemark } = node
            const { frontmatter } = childMarkdownRemark
            return (
              <BlogPostSmall key={childMarkdownRemark.id}>
                {frontmatter.coverImage != null ?
                  <Link to={`/blog/${frontmatter.title.substr(0,100).replace(/[? *]/g, '-')}`}>
                  {
                    frontmatter.coverImage.childImageSharp != null ? (
                      <Img
                        fluid={frontmatter.coverImage.childImageSharp.fluid}
                      />
                    ) : (
                      <img src={frontmatter.coverImage.publicURL} style={{ marginBottom: '-0.5rem' }} />
                    )
                  }
                  </Link>
                  : null
                }
                <CardText>
                  <BlogPostLink to={`/blog/${frontmatter.title.substr(0,100).replace(/[? *]/g, '-')}`}>
                    <Date>
                      {moment(frontmatter.date).format('dd DD.MM')}
                    </Date>
                    <Title>{frontmatter.title}</Title>
                  </BlogPostLink>
                </CardText>
              </BlogPostSmall>
            )
          })
        }
      </BlogPosts>
    </BlogPostsWrapper>
  )
}

export default RelatedBlogPosts
