import React from 'react'
import { graphql } from 'gatsby'
import Img from "gatsby-image"
import Layout from '../components/layout'
import RelatedBlogPosts from '../components/related-blogposts'
import marked from "marked"
import ReactMarkdown from 'react-markdown'
import PlainTextRenderer from "marked-plaintext"
import styled from 'styled-components'
import moment from 'moment'
import 'moment/locale/nl'
import { Facebook } from 'react-social-sharing'
import SEO from "../components/seo"

moment.locale('nl')

const convertToPlainText = (markdownText) => {
  const renderer = new PlainTextRenderer()
  return marked(markdownText, { renderer })
}

const BlogPost = styled.div`
  color: #666;
  margin-top: 20px;
  display: flex;
  width: 100%;
  flex-direction: column;
`

const Header = styled.h3`
  margin-bottom: 40px;
`

const Content = styled.span`
  & a {
    color: #279896;
  }
`

const Cover = styled.div`
  position: relative;
`

const CoverText = styled.span`
  position: absolute;
  padding: 10px;
  font-size: 16px;
  top:20px;
  right: 10px;
  color: #279896;
  background: rgba(255,255,255,1);
  position: absolute;
  font-weight: bold;
`

const FacebookWrapper = styled.div`
  margin: 40px 0 80px 0;
  & a {
    margin:0;
  }
`

const DetailTemplate = ({ data }) => {
  const { frontmatter, rawMarkdownBody } = data.blogpost
  return (
   <Layout>
    <SEO
      title={frontmatter.title}
      description={convertToPlainText(rawMarkdownBody).substring(0, 260) + '...'}
      type={'article'}
      image={`${typeof window !== "undefined" ? window.location.host : ''}${frontmatter.coverImage.childImageSharp != null ? frontmatter.coverImage.childImageSharp.fluid.src : frontmatter.coverImage.publicURL}`}
    />
    <Cover>
      {
        frontmatter.coverImage.childImageSharp != null ? (
          <Img fluid={frontmatter.coverImage.childImageSharp.fluid} />
        ) : (
          <img src={frontmatter.coverImage.publicURL} width='100%' />
        )
      }
      <CoverText>
        <div>{`${frontmatter.year}#${frontmatter.season}`}</div>
      </CoverText>
    </Cover>
    <BlogPost>
      <Header>{moment(frontmatter.date).format('dd DD.MM')} / {frontmatter.title}</Header>
      <Content>
        <ReactMarkdown
          parserOptions={{ commonmark: true }}
          source={rawMarkdownBody}
        />
      </Content>
    </BlogPost>

    <FacebookWrapper>
      <Facebook big link={typeof window !== "undefined" ? window.location.href : ''} label={serviceName => ('Deel dit op facebook')} />
    </FacebookWrapper>

    <RelatedBlogPosts data={data} />
   </Layout>
  )
}

export default DetailTemplate

export const query = graphql`
  query DetailTemplate($id: String!, $year: Date!, $season: String!) {
    blogpost: markdownRemark(id: {eq: $id}) {
      id
      rawMarkdownBody
      frontmatter {
        date
        title
        coverImage {
          childImageSharp {
            fluid(maxWidth: 920, maxHeight: 500, cropFocus: CENTER ) {
              ...GatsbyImageSharpFluid
            }
          }
          publicURL
        }
        year
        season
      }
    }
    blogposts: allFile(filter: {relativeDirectory: {eq: "blog"}, childMarkdownRemark: {frontmatter: {year: {eq: $year}, season: {eq: $season}}} }, sort: {fields: childMarkdownRemark___frontmatter___date, order: DESC}) {
      edges {
        node {
          childMarkdownRemark {
            id
            rawMarkdownBody
            frontmatter {
              date
              title
              coverImage {
                childImageSharp {
                  fluid(maxWidth: 450, cropFocus: CENTER) {
                    ...GatsbyImageSharpFluid
                  }
                }
                publicURL
              }
            }
          }
        }
      }
    }
  }
`